/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-131tz7d pb--60 pt--80" name={"uvod-1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/25609/81f186ba926341c9aa6f668ee55a2045_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/25609/81f186ba926341c9aa6f668ee55a2045_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/25609/81f186ba926341c9aa6f668ee55a2045_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/25609/81f186ba926341c9aa6f668ee55a2045_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/25609/81f186ba926341c9aa6f668ee55a2045_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/25609/81f186ba926341c9aa6f668ee55a2045_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/25609/81f186ba926341c9aa6f668ee55a2045_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/25609/81f186ba926341c9aa6f668ee55a2045_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 pb--10 pt--10 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-custom-1);\">Ceník</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--60" name={"ufdypsxbkp"} style={{"paddingBottom":124}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box fs--20" content={"Týden (6 nocí)"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":7}} content={"<span style=\"font-weight: bold;\">30.000,- Kč (+ každá další noc 3.000,- Kč navíc)</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box fs--20" content={"Víkend (pá-ne)"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":7}} content={"<span style=\"font-weight: bold;\">16.000,- Kč</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box fs--20" content={"Prodloužený víkend (čt-ne)"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":7}} content={"<span style=\"font-weight: bold;\">20.000,- Kč</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--top" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box fs--20" content={"4 pracovní dny (po-čt)"}>
              </Text>

              <Subtitle className="subtitle-box" style={{"marginTop":7}} content={"<span style=\"font-weight: bold;\">16.000,- Kč</span>"}>
              </Subtitle>

              <Text className="text-box text-box--justify fs--16" content={"– O letních prázdninách (červenec &amp; srpen) preferujeme týdenní týdenní od soboty do soboty.&nbsp;<br>– Vratná kauce: 20 % z&nbsp;ceny pobytu, která je splatná v den příjezdu v hotovosti."}>
              </Text>

              <Text className="text-box text-box--left fs--16" style={{"marginTop":0}} content={"– Wi-Fi, internetová TV, dřevo do krbu a povlečení jsou v&nbsp;ceně.<br>"}>
              </Text>

              <Text className="text-box fs--20 w--500" content={"Specifické termíny:\n"}>
              </Text>

              <Subtitle className="subtitle-box fs--16" style={{"marginTop":7}} content={"<span style=\"font-weight: bold;\">Velikonoce + 5.000,- Kč<br><br>Vánoce, Silvestr + 10.000,- Kč\n<br><br>Mistrovství světa v biatlonu Nové Město na Moravě + 20% z obvyklé ceny pobytu.<br><br>Světový pohár horských kol Nové Město na Moravě + 20% z obvyklé ceny pobytu.<br></span>"}>
              </Subtitle>

              <Text className="text-box text-box--justify fs--16" content={"Obec Lísek zavedla svou vyhláškou místní poplatek z&nbsp;pobytu. Poplatek činí 10,- Kč za každý započatý den pobytu na jednu osobu. Součástí vyhlášky je povinnost evidence hostů. Poplatek z&nbsp;pobytu je účtován samostatně a bude vybrán v&nbsp;hotovosti v&nbsp;den zahájení pobytu. K&nbsp;evidenci hostů jsou zapotřebí údaje z&nbsp;občanského průkazu všech ubytovaných osob.\n<br><br><span style=\"font-weight: bold;\">Informace k elektrické energii:</span> <br>V ceně pobytu zahrnuta spotřeba ve výši <span style=\"font-weight: bold;\">126 kWh</span>&nbsp;(500 Kč) pro víkendové pobyty a prodloužené víkendy a pro týdenní pobyty cena pobytu zahrnuje <span style=\"font-weight: bold;\">252 kWh</span> (1000 Kč). Nad rámec této paušální spotřeby bude hostům účtován doplatek spotřeby elektrické energie podle platného ceníku dodavatele elektrické energie. Spotřeba bude zjišťována tak, že v den začátku pobytu bude transparentně zaznamenám stav elektroměru a porovnán se stavem elektroměru v den odjezdu. V případě překročení paušální spotřeby, bude vyúčtován nedoplatek, který bude splatný v den odjezdu v hotovosti oproti potvrzení.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--top" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="--left mt--0 pt--0" style={{"marginBottom":0}}>
              
              <Text className="text-box text-box--justify fs--16" content={"<span style=\"font-weight: bold;\">Check-in:</span>&nbsp;od 14h v den příjezdu či jinak po dohodě"}>
              </Text>

              <Text className="text-box text-box--justify fs--16" style={{"marginTop":4}} content={"<span style=\"font-weight: bold;\">Check-out:&nbsp;</span>do 10h v den odjezdu či jinak po dohodě\n"}>
              </Text>

              <Text className="text-box text-box--left" content={"Rezervaci lze bezplatně zrušit 15 dnů před začátkem pobytu, jinak je účtován storno poplatek ve výši 50 % celkové ceny pobytu. <br><br><iframe src=\"https://obsazenost.e-chalupy.cz/kalendar.php?id=15358&amp;pocetMesicu=12&amp;velikost=3&amp;legenda=ano&amp;vybraneMesice=&amp;naStred=ano&amp;ctvrtleti=ano&amp;stin=ano&amp;jazyk=cz&amp;jednotky=ano&amp;idJednotky=0&amp;vypisJednotky=ne&amp;souhrnny=&amp;pozadi=ffffff&amp;kalendarText=000000&amp;kalendarPozadi=ffffff&amp;ramecek=dddddd&amp;mesicText=555555&amp;mesicPozadi=dddddd&amp;dnyText=008000&amp;dnyPozadia=ffffff&amp;obsazenoText=222222&amp;obsazenoPozadi=f74848&amp;volnoText=222222&amp;volnoPozadi=67cf67&amp;castecneText=222222&amp;castecnePozadi=489ce0&amp;neaktivniDnyText=bbbbbb&amp;neaktivniDnyPozadi=ffffff&amp;legendaText=000000&amp;fontFamily=Baloo%20Chettan%202&amp;extCss=\" height=\"460px\" width=\"100%\" frameborder=\"0\" id=\"echalupy-kalendar\"></iframe><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--25" name={"paticka"} style={{"backgroundColor":"rgba(21,21,22,1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2" anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Subtitle className="subtitle-box fs--20 w--600" content={"<span style=\"color: var(--color-custom-2);\">Chalupavojtechov.cz</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2);\">Vojtěchov č. ev. 107<br>593 01 Lísek<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--20 lh--16" content={"<br><span style=\"color: rgb(201, 201, 201);\">+420</span> <span style=\"color: var(--color-custom-2);\">774 344 014</span><br><a href=\"mailto:nikola@chalupavojtechov.cz?subject=Zpr%C3%A1va+z+webu\"><span style=\"color: rgb(201, 201, 201);\">nikola</span><span style=\"color: var(--color-custom-2);\">@chalupavojtechov.cz</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}